import styled from 'styled-components';
import { COLORS, easyMove } from '../style-constants';

const MarkerCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  padding: 8px;
  margin-left: -10px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border: 4px solid #00E0CD90;
  border-radius: 50%;
  background-color: ${COLORS.aqua};
  transition: transform 0.3s;
  animation: ${easyMove} 0.3s;

  &:hover {
    transform: scale(1.2);
    cursor:pointer;
  }
`;

export default MarkerCounter;
