import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { withNamespaces } from "react-i18next";
import {getFirebase} from '../libs/firebase';

class MissionDetails extends React.Component {

  state = {
    showingClass : "",
    cover:"",
    coverLoaded:false
  }

  componentDidMount() {
    this.show();

    const lazyApp = import('firebase/app')
    const lazyStorage = import('firebase/storage')

    Promise.all([lazyApp, lazyStorage]).then(([firebase]) => {
      const storage = getFirebase(firebase).storage();
      this.getCoverUrl(storage);
    })
  }

  componentWillUnmount() {
  }

  hide() {
    this.setState({
      showingClass:"",
    })

    setTimeout(() => {
      this.props.callbackClose();
    }, 1100);
  }

  show() {
    this.setState({
      showingClass:"shown"
    })
  }

  generateDiddiculty(val, max) {
    let res = []
    for(let i = 0 ; i < max ; i++) {
      let classVal = "indicator";
      if(i < val)
        classVal += " on";

      res.push(<span className={classVal} key={i}></span>);      
    }
    return res;
  }

  formatDistance(min, max) {
    let distance = min;

    if (distance < 1000)
      return distance+"m";
    else
      return (distance/1000)+"km";
  }

  formatTime(min, max) {
    const duration = min;
    let minutes = Math.floor((duration / 60) % 60);
    let hours = Math.floor(duration / (60*60));

    minutes = (minutes < 10 && hours === 0) ? "0" + minutes : minutes;

    let result = ""

    if(hours > 0)
      result = hours + "h";

    if(minutes > 0)
      result += minutes + "min";

    return result;
  }

  getCityName() {
    /*
    const {cities, mission} = this.props;
    const ln = "fr";

    if(!cities|| cities.length == 0)
      return "";

    return cities[mission.cityId].name[ln]
    */
  }

  getPrice(val) {
    const {mission, t} = this.props;

    if(val === 0)
      return t("free");
    else 
      return (mission.vendingInfo.price/100)+ "€"
  }

  getCoverUrl(firebaseStorage) {
    const {mission} = this.props;
    const firebaseFile = firebaseStorage.ref(`releases/scenarios/${mission.id}`);
    return firebaseFile.child(`assets/${mission.cover.fileName.default}`).getDownloadURL().then((url) => {
      console.log(url);
      this.setState({
        cover: url
      })
    });
  }

  render() {
    const { mission, t, i18n } = this.props;
    const ln = i18n.language;
    console.log(mission.vendingInfo);

    return (
      <div id='mission-details' className={this.state.showingClass} >
        <div onClick={() => this.hide()} style={{position:"absolute",top:0,left:0,right:0,bottom:0}}>

        </div>
        <div id='mission-details-content' className={this.state.showingClass} onClick={() => {}}>
            <div id='header'>
                <div id="cover-back">
                  { this.state.cover && <img src={this.state.cover} onLoad={() => { this.setState({coverLoaded : true})} } alt="cover"/> }
                </div>
                <div id="cover-layer" className={(this.state.coverLoaded) ? "hide" : null}>
                  { !this.state.coverLoaded && <img src={require("../images/illustrations/icons/bars.svg")} alt="loading anim"/> }
                </div>

                <div onClick={() => this.hide()} style={{position:"absolute",right:10,top:10,color:"white",fontWeight:"bold",backgroundColor:"rgba(0,0,0,0.2)",cursor:"pointer"}}>
                  X
                </div>
                <h2>{/**this.getCityName()**/}</h2>
                <h1>{mission.name[ln]}</h1>
                <div id="difficulty-container">
                  {this.generateDiddiculty(mission.difficulty, 5)}
                  <span id="dif-label">
                    {t("difficulty-"+mission.difficulty)}
                  </span>
                </div>
            </div>

            <div id="sub-header">
                <div className="case">
                  <span className="value">+{mission.ageMin} ans</span>
                  <span className="legend">{t("age-min")}</span>
                </div>
                <div className="case">
                  <span className="value">±{this.formatTime(mission.durationMin, mission.durationMax)}</span>
                  <span className="legend">{t("duration")}</span>
                </div><div className="case">
                  <span className="value">±{this.formatDistance(mission.distanceMin, mission.distanceMax)}</span>
                  <span className="legend">{t("distance")}</span>
                </div>
            </div>

            <div id="content-container">
              <h1 id="year">{mission.subtitle[ln]}</h1>

              <h1>{t("history")}</h1>
              <p>
                {mission.pitch[ln]}
              </p>

              <div className="your-mission">
                <h1>{t("your-mission")}</h1>
                <p>
                  {mission.missionText[ln]}
                </p>
              </div>

              <h1>{t("langue")}</h1>
              <div className="ln-container">
                {
                  mission.managedLocales.map((val, key) => {
                    return (
                      <div className="ln" key={key}>
                        <img src={require("../images/illustrations/icons/countries/"+val+".png")} alt={"flag-"+val}/>
                        {t("ln-"+val)}
                      </div>
                    )
                  })
                }
              </div>

              {
              !(mission.vendingInfo.externalSeller) && 
              <div>
                <h1>{t("price")}</h1>
                <div id="price">
                  <span className="value-container"><span className="value">{this.getPrice(mission.vendingInfo.price)}</span> {(mission.vendingInfo.price > 0)?"/ "+t("phone"):""}</span>
                  <span className="legend">
                    {t("phone-info")}
                  </span>
                </div>
              </div>
              }
            </div>
        </div>
      </div>
    )
  }
}

export default withNamespaces("MissionDetails")(MissionDetails);


