import React from 'react'
import { Link } from "gatsby";
import { compose, withProps } from "recompose"
import Layout from "../components/layout";
import {getFirebase} from '../libs/firebase';
import SEO from "../components/seo"
import NavBar from "../components/navbar"
import Loader from "../components/loader"
import MissionDetails from "../components/missionDetails"
import GoogleMap from '../components/GoogleMaps';
import { withNamespaces } from "react-i18next";
import { navigate } from "@reach/router"

import mapS from "../assets/config/maps.json"


class Missions extends React.Component {

  state = {
    missions: null,
    cities:null,
    markersData: [],
    loading:true,
    missionSelected:null,
  }

  googleMap = React.createRef();
  firebaseStorage = null;

  componentDidMount() {
    const lazyApp = import('firebase/app')
    const lazyDatabase = import('firebase/database')
    const lazyStorage = import('firebase/storage')

    Promise.all([lazyApp, lazyDatabase, lazyStorage]).then(([firebase]) => {
      const database = getFirebase(firebase).database();
      this.firebaseStorage = getFirebase(firebase).storage();
      this.updateMissions(database);
    })
  }

  updateMissions(database) {
    database.ref("releases/scenarios/catalog/prod/").on('value', (snapshot) => {
      let missions = Object.values(snapshot.val());
      console.log(missions);
      missions = missions.filter((mission) => {
        return mission.isOfficial && mission.vendingInfo.visible && !mission.vendingInfo.comingSoon && mission.cityId !== "sch" && mission.cityId !== "stm"
      })

      const markersData = missions.map( (mission, index) => {
        return {
          id: index,
          lat:mission.startPoint.latitude,
          lng:mission.startPoint.longitude,
        }
      });

      this.googleMap.current.refresh();

      this.setState({
        missions,
        markersData,
        loading:false
      });

      let {missionId} = this.props;
      if(! missionId && this.props.location.search) {
        const params = this.getUrlParams(this.props.location.search);
        missionId = params.id;
      }

      if(missionId) {
        missions.map((element, key) => {
          if(element.id === missionId) {
            missionId = key;
          }
        });
        this.selectMission(missionId);
      }
    });

    /*
    database.ref("releases/cities/catalog/prod/").on('value', (snapshot) => {
      let cities = snapshot.val();
      this.setState({
        cities
      });
    });
    */
  }

  getUrlParams(search) {
    let hashes = search.slice(search.indexOf('?') + 1).split('&')
    let params = {}
    hashes.map(hash => {
        let [key, val] = hash.split('=')
        params[key] = decodeURIComponent(val)
    })

    return params
  }
  
  getCoverUrl(mission) {
    const firebaseFile = this.firebaseStorage.ref(`releases/scenarios/${mission.id}`);
    return firebaseFile.child(`assets/${mission.cover.fileName.default}`).getDownloadURL().then((url) => {
      console.log(url);
      this.setState({
        missionCoverImageSet: url
      })
    });
  }

  unSelectMission() {
    this.setState({
      missionSelected:null,
      missionCoverImageSet: null
    });
    navigate(`/app/missions/`);
  }
  
  selectMission(id) {
    this.setState({
      missionSelected:id
    });

    this.getCoverUrl(this.state.missions[id]);

    navigate(`/app/missions/${this.state.missions[id].id}`)
  }

  render() {
    const ln = this.props.i18n.language.split("-")[0];
    const {t} = this.props;

    let description, title, urlImage, forceFullPath;

    if(this.state.missionSelected && this.state.missions) {
      const mission = this.state.missions[this.state.missionSelected];

      description = mission.pitch[ln];
      title = mission.name[ln];

      if(this.state.missionCoverImageSet) {
        urlImage = this.state.missionCoverImageSet;
        forceFullPath = true;
      }
    } else {
      title = "Missions";
      urlImage = "share_missions.jpg"
      forceFullPath = false;
    }

    return (
      <Layout>
        <SEO title={title} image={urlImage} lang={ln} description={description} forceFullPathImage={forceFullPath}/>  

        <Loader force={this.state.loading}/>
        
        <div id="landing-hero" className="hero is-relative is-theme-secondary">
          <NavBar siteTitle="Atlantide IO" foceSolide/>

          <GoogleMap 
            ref={this.googleMap}
            defaultCenter={{ lat: 48.864716, lng: 2.349014 }} 
            defaultZoom={4}
            devMode={activeEnv === "development"}
            options={{
              styles:mapS,
              disableDefaultUI:true,
              gestureHandling: 'greedy'
            }}
            markersData={this.state.markersData}
            onSelectMarker={(id) => {this.selectMission(id)}}
            />

          {
            (this.state.missionSelected && this.state.missions) ?
            <MissionDetails 
              callbackClose={() => this.unSelectMission() } 
              mission={this.state.missions[this.state.missionSelected]} 
              />:
            null
          }

        </div>
      </Layout>
    )
  }
}

let googleMapURL = "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
let activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

export default withNamespaces("MissionDetails")(Missions)
