import React from 'react';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';

import Marker from './Marker';
import ClusterMarker from './ClusterMarker';

import MapWrapper from './MapWrapper';


export class GoogleMap extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  state = {
    clusters: [],
  };

  googleMap = React.createRef();

  getClusters = (props) => {


    const clusters = supercluster(props.markersData, {
      minZoom: 0,
      maxZoom: 16,
      radius: 60,
    });

    return clusters(this.state.mapOptions);
  };

  createClusters = props => {
    this.setState({
      clusters: this.state.mapOptions.bounds
        ? this.getClusters(props).map(({ wx, wy, numPoints, points }) => ({
            lat: wy,
            lng: wx,
            numPoints,
            id: `${points[0].id}`,
            points,
          }))
        : [],
    });
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
      },
      () => {
        this.createClusters(this.props);
      }
    );
  };

  zoomIn = ( center ) => {
    this.googleMap.current.map_.setCenter(center);
    this.googleMap.current.map_.setZoom(this.googleMap.current.map_.zoom+2);
  };

  refresh = () => {
    this.googleMap.current.map_.setZoom(this.googleMap.current.map_.zoom+1);
  };

  render() {
    let urlOption = {}; 
    if(!this.props.devMode)
      urlOption.key = process.env.GATSBY_MAPS_API_KEY;

    return (
      <MapWrapper>
        <GoogleMapReact
          ref={this.googleMap}
          defaultZoom={this.props.defaultZoom}
          defaultCenter={this.props.defaultCenter}
          options={this.props.options}
          onChange={this.handleMapChange}
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={urlOption}
        >
          {this.state.clusters.map(item => {
            if (item.numPoints === 1) {
              return (
                <Marker
                  key={item.id}
                  lat={item.points[0].lat}
                  lng={item.points[0].lng}
                  onClick={() => this.props.onSelectMarker(item.id)}
                />
              );
            }

            return (
              <ClusterMarker
                key={item.id}
                lat={item.lat}
                lng={item.lng}
                points={item.points}
                onClusterClick={(position) => this.zoomIn(position)}
              />
            );
          })}
        </GoogleMapReact>
      </MapWrapper>
    );
  }
}

export default GoogleMap;
