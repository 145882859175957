import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import Marker from '../Marker';

import MarkerGroup from './MarkerGroup';
import MarkerCounter from './MarkerCounter';

class ClusterMarker extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  state = {
    clusterFaceMarkers: this.props.points.slice(0, 2),
  };

  render() {
    return (
      <MarkerGroup length={this.props.points.length} onClick={() => { this.props.onClusterClick({lat:this.props.lat, lng:this.props.lng})}}>
        {
          (this.props.points.length > 1) ?
          <MarkerCounter>
            {this.props.points.length}
          </MarkerCounter>
          : 
          null
        }
      </MarkerGroup>
    );
  }
}

ClusterMarker.propTypes = {
  points: PropTypes.array,
  users: PropTypes.instanceOf(List),
  selected: PropTypes.bool,
};

export default ClusterMarker;
