import React from 'react';
import PropTypes from 'prop-types';

function AtlantideLogo(props) {
  const styles = {
    transform: `scale(${props.scale})`,
  };

  return (
    <img
      width="100"
      height="100"
      src={require("./mission_single.svg")}
      style={styles}
    />
  );
}

AtlantideLogo.propTypes = {
  scale: PropTypes.string,
};

export default AtlantideLogo;
