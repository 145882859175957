import React from 'react';
import PropTypes from 'prop-types';

import MarkerStyled from './MarkerStyled';
import MarkerInGroupStyled from './MarkerInGroupStyled';
import Spy from '../img/Spy';
import AtlantideLogo from '../img/AtlantideLogo';

class Marker extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  static defaultProps = {
    inGroup: false,
  };

  render() {
    return (
      <div onClick={() => this.props.onClick(this.props.key)}>
        {this.props.inGroup
          ? <MarkerInGroupStyled>
              <Spy scale="0.55" />
            </MarkerInGroupStyled>
          : <MarkerStyled>
              <AtlantideLogo scale="0.55" />
            </MarkerStyled>}
      </div>
    );
  }
}

Marker.propTypes = {
  inGroup: PropTypes.bool,
};

export default Marker;
